import { reactive } from 'vue';

export default reactive({
    items: [],
    add(toast) {
        this.items.unshift({
            key: Symbol(),
            ...toast,
        });
    },
    remove(index) {
        this.items.splice(index, 1);
    },
    success(message) {
        this.messageType(message, 'success');
    },
    info(message) {
        this.messageType(message, 'info');
    },
    error(message) {
        this.messageType(message, 'error');
    },
    messageType(message, type) {
        this.items.unshift({
            key: Symbol(),
            message: message,
            type: type,
        });
    },
    addMultiple(...toast) {
        if (toast.length === 1) {
            toast[0].forEach((item) => {
                this.items.unshift({
                    key: Symbol(),
                    message: item['message'],
                    type: item['type'],
                });
            });
        } else {
            toast.forEach((item) => {
                this.items.unshift({
                    key: Symbol(),
                    message: item['message'],
                    type: item['type'],
                });
            });
        }
    },
});
