<template>
    <modal
        :show="show"
        :max-width="maxWidth"
        :closeable="closeable"
        @close="close"
    >
        <div :class="wrapperClasses">
            <div v-if="slots.title" class="text-lg">
                <slot name="title" />
            </div>

            <div class="mt-4">
                <slot name="content" />
            </div>
        </div>

        <div
            v-if="showFooter"
            class="px-6 rounded-b-lg py-4 bg-gray-100 text-right"
        >
            <slot name="footer" />
        </div>
    </modal>
</template>

<script setup>
    import Modal from '@/Jetstream/Modal.vue';
    import { useSlots } from 'vue';

    const { show, maxWidth, closeable, showFooter } = defineProps({
        show: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '2xl',
        },
        closeable: {
            type: Boolean,
            default: true,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        wrapperClasses: {
            type: String,
            default: 'px-6 py-4',
        },
    });

    const slots = useSlots();

    const emits = defineEmits(['close']);

    const close = () => {
        emits('close');
    };
</script>
