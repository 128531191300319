<template>
    <teleport to="body">
        <transition leave-active-class="duration-200">
            <div
                v-show="show"
                class="fixed inset-0 overflow-y-auto px-4 py-6 sm:px-0 z-50"
                scroll-region
            >
                <transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div
                        v-show="show"
                        class="fixed inset-0 transform transition-all"
                        @click="close"
                    >
                        <div class="absolute inset-0 bg-gray-500 opacity-75" />
                    </div>
                </transition>

                <transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        v-show="show"
                        class="mb-6 bg-white rounded-lg overflow-visible shadow-xl transform transition-all sm:w-full sm:mx-auto"
                        :class="maxWidthClass"
                    >
                        <slot v-if="show" />
                    </div>
                </transition>

                <button
                    class="fixed left-full top-2/4 rotate-90 origin-top-left whitespace-nowrap bg-primary text-white px-5 py-2 rounded-br-lg rounded-bl-lg font-bold text-xs md:text-sm"
                    @click="showSupportModal = true"
                >
                    Support
                </button>

                <SupportModal
                    :show="showSupportModal"
                    @close="showSupportModal = false"
                />
            </div>
        </transition>
    </teleport>
</template>

<script>
    import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
    import SupportModal from '@/Layouts/Components/SupportModal.vue';

    export default defineComponent({
        components: { SupportModal },

        props: {
            show: {
                default: false,
            },
            maxWidth: {
                default: '2xl',
            },
            closeable: {
                default: true,
            },
        },
        emits: ['close'],

        setup(props, { emit }) {
            const close = () => {
                if (props.closeable) {
                    emit('close');
                }
            };
            const showSupportModal = ref(false);

            const closeOnEscape = (e) => {
                if (e.key === 'Escape' && props.show) {
                    close();
                }
            };

            onMounted(() =>
                document.addEventListener('keydown', closeOnEscape)
            );
            onUnmounted(() => {
                document.removeEventListener('keydown', closeOnEscape);
                document.body.style.overflow = null;
            });

            return {
                close,
                showSupportModal,
            };
        },

        computed: {
            maxWidthClass() {
                return {
                    sm: 'sm:max-w-sm',
                    md: 'sm:max-w-md',
                    lg: 'sm:max-w-lg',
                    xl: 'sm:max-w-xl',
                    '2xl': 'sm:max-w-2xl',
                    '3xl': 'sm:max-w-3xl',
                    '4xl': 'sm:max-w-4xl',
                    '5xl': 'sm:max-w-5xl',
                    '6xl': 'sm:max-w-6xl',
                    '7xl': 'sm:max-w-7xl',
                }[this.maxWidth];
            },
        },

        watch: {
            show: {
                immediate: true,
                handler(show) {
                    if (show) {
                        document.body.style.overflow = 'hidden';
                    } else {
                        document.body.style.overflow = null;
                    }
                },
            },
        },
    });
</script>
