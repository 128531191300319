<template>
    <button
        :type="type"
        :disabled="disabled"
        class="mr-3 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-bold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
    >
        <slot />
    </button>
</template>

<script>
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            type: {
                type: String,
                default: 'button',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
    });
</script>
